* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time,
mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

body {
  line-height: 1; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

ol, ul {
  list-style: none; }

strong, b {
  font-weight: bold; }

input:focus {
  outline: none; }

blockquote, q {
  quotes: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a[ng-click], div[ng-click], p[ng-click], span[ng-click], img[ng-click], a[ui-sref], div[ui-sref], p[ui-sref], span[ui-sref], img[ui-sref] {
  cursor: pointer; }

textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
  outline: 0px !important;
  -webkit-appearance: none; }

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important; }

a {
  -webkit-tap-highlight-color: transparent; }

body {
  font-family: "san_francisco", sans-serif; }

.grid {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + 1% + 1%);
  margin-left: -1%;
  margin-right: -1%; }
  .grid .column {
    padding-right: 1%;
    padding-left: 1%; }
  .grid.largeGaps {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + 2.2% + 2.2%);
    margin-left: -2.2%;
    margin-right: -2.2%; }
    .grid.largeGaps .column {
      padding-right: 2.2%;
      padding-left: 2.2%; }

.col1 {
  width: 8.33333%; }

.col2 {
  width: 16.66667%; }

.col3 {
  width: 25%; }

.col4 {
  width: 33.33333%; }

.col5 {
  width: 41.66667%; }

.col6 {
  width: 50%; }

.col7 {
  width: 58.33333%; }

.col8 {
  width: 66.66667%; }

.col9 {
  width: 75%; }

.col10 {
  width: 83.33333%; }

.col11 {
  width: 91.66667%; }

.col12 {
  width: 100%; }

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 8.33333%; }
  .col2_1800 {
    width: 16.66667%; }
  .col3_1800 {
    width: 25%; }
  .col4_1800 {
    width: 33.33333%; }
  .col5_1800 {
    width: 41.66667%; }
  .col6_1800 {
    width: 50%; }
  .col7_1800 {
    width: 58.33333%; }
  .col8_1800 {
    width: 66.66667%; }
  .col9_1800 {
    width: 75%; }
  .col10_1800 {
    width: 83.33333%; }
  .col11_1800 {
    width: 91.66667%; }
  .col12_1800 {
    width: 100%; } }

@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 8.33333%; }
  .col2_1700 {
    width: 16.66667%; }
  .col3_1700 {
    width: 25%; }
  .col4_1700 {
    width: 33.33333%; }
  .col5_1700 {
    width: 41.66667%; }
  .col6_1700 {
    width: 50%; }
  .col7_1700 {
    width: 58.33333%; }
  .col8_1700 {
    width: 66.66667%; }
  .col9_1700 {
    width: 75%; }
  .col10_1700 {
    width: 83.33333%; }
  .col11_1700 {
    width: 91.66667%; }
  .col12_1700 {
    width: 100%; } }

@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 8.33333%; }
  .col2_1600 {
    width: 16.66667%; }
  .col3_1600 {
    width: 25%; }
  .col4_1600 {
    width: 33.33333%; }
  .col5_1600 {
    width: 41.66667%; }
  .col6_1600 {
    width: 50%; }
  .col7_1600 {
    width: 58.33333%; }
  .col8_1600 {
    width: 66.66667%; }
  .col9_1600 {
    width: 75%; }
  .col10_1600 {
    width: 83.33333%; }
  .col11_1600 {
    width: 91.66667%; }
  .col12_1600 {
    width: 100%; } }

@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 8.33333%; }
  .col2_1500 {
    width: 16.66667%; }
  .col3_1500 {
    width: 25%; }
  .col4_1500 {
    width: 33.33333%; }
  .col5_1500 {
    width: 41.66667%; }
  .col6_1500 {
    width: 50%; }
  .col7_1500 {
    width: 58.33333%; }
  .col8_1500 {
    width: 66.66667%; }
  .col9_1500 {
    width: 75%; }
  .col10_1500 {
    width: 83.33333%; }
  .col11_1500 {
    width: 91.66667%; }
  .col12_1500 {
    width: 100%; } }

@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 8.33333%; }
  .col2_1400 {
    width: 16.66667%; }
  .col3_1400 {
    width: 25%; }
  .col4_1400 {
    width: 33.33333%; }
  .col5_1400 {
    width: 41.66667%; }
  .col6_1400 {
    width: 50%; }
  .col7_1400 {
    width: 58.33333%; }
  .col8_1400 {
    width: 66.66667%; }
  .col9_1400 {
    width: 75%; }
  .col10_1400 {
    width: 83.33333%; }
  .col11_1400 {
    width: 91.66667%; }
  .col12_1400 {
    width: 100%; } }

@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 8.33333%; }
  .col2_1300 {
    width: 16.66667%; }
  .col3_1300 {
    width: 25%; }
  .col4_1300 {
    width: 33.33333%; }
  .col5_1300 {
    width: 41.66667%; }
  .col6_1300 {
    width: 50%; }
  .col7_1300 {
    width: 58.33333%; }
  .col8_1300 {
    width: 66.66667%; }
  .col9_1300 {
    width: 75%; }
  .col10_1300 {
    width: 83.33333%; }
  .col11_1300 {
    width: 91.66667%; }
  .col12_1300 {
    width: 100%; } }

@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 8.33333%; }
  .col2_1200 {
    width: 16.66667%; }
  .col3_1200 {
    width: 25%; }
  .col4_1200 {
    width: 33.33333%; }
  .col5_1200 {
    width: 41.66667%; }
  .col6_1200 {
    width: 50%; }
  .col7_1200 {
    width: 58.33333%; }
  .col8_1200 {
    width: 66.66667%; }
  .col9_1200 {
    width: 75%; }
  .col10_1200 {
    width: 83.33333%; }
  .col11_1200 {
    width: 91.66667%; }
  .col12_1200 {
    width: 100%; } }

@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 8.33333%; }
  .col2_1100 {
    width: 16.66667%; }
  .col3_1100 {
    width: 25%; }
  .col4_1100 {
    width: 33.33333%; }
  .col5_1100 {
    width: 41.66667%; }
  .col6_1100 {
    width: 50%; }
  .col7_1100 {
    width: 58.33333%; }
  .col8_1100 {
    width: 66.66667%; }
  .col9_1100 {
    width: 75%; }
  .col10_1100 {
    width: 83.33333%; }
  .col11_1100 {
    width: 91.66667%; }
  .col12_1100 {
    width: 100%; } }

@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 8.33333%; }
  .col2_1024 {
    width: 16.66667%; }
  .col3_1024 {
    width: 25%; }
  .col4_1024 {
    width: 33.33333%; }
  .col5_1024 {
    width: 41.66667%; }
  .col6_1024 {
    width: 50%; }
  .col7_1024 {
    width: 58.33333%; }
  .col8_1024 {
    width: 66.66667%; }
  .col9_1024 {
    width: 75%; }
  .col10_1024 {
    width: 83.33333%; }
  .col11_1024 {
    width: 91.66667%; }
  .col12_1024 {
    width: 100%; } }

@media screen and (max-width: 900px) {
  .col1_900 {
    width: 8.33333%; }
  .col2_900 {
    width: 16.66667%; }
  .col3_900 {
    width: 25%; }
  .col4_900 {
    width: 33.33333%; }
  .col5_900 {
    width: 41.66667%; }
  .col6_900 {
    width: 50%; }
  .col7_900 {
    width: 58.33333%; }
  .col8_900 {
    width: 66.66667%; }
  .col9_900 {
    width: 75%; }
  .col10_900 {
    width: 83.33333%; }
  .col11_900 {
    width: 91.66667%; }
  .col12_900 {
    width: 100%; } }

@media screen and (max-width: 768px) {
  .col1_768 {
    width: 8.33333%; }
  .col2_768 {
    width: 16.66667%; }
  .col3_768 {
    width: 25%; }
  .col4_768 {
    width: 33.33333%; }
  .col5_768 {
    width: 41.66667%; }
  .col6_768 {
    width: 50%; }
  .col7_768 {
    width: 58.33333%; }
  .col8_768 {
    width: 66.66667%; }
  .col9_768 {
    width: 75%; }
  .col10_768 {
    width: 83.33333%; }
  .col11_768 {
    width: 91.66667%; }
  .col12_768 {
    width: 100%; } }

@media screen and (max-width: 700px) {
  .col1_700 {
    width: 8.33333%; }
  .col2_700 {
    width: 16.66667%; }
  .col3_700 {
    width: 25%; }
  .col4_700 {
    width: 33.33333%; }
  .col5_700 {
    width: 41.66667%; }
  .col6_700 {
    width: 50%; }
  .col7_700 {
    width: 58.33333%; }
  .col8_700 {
    width: 66.66667%; }
  .col9_700 {
    width: 75%; }
  .col10_700 {
    width: 83.33333%; }
  .col11_700 {
    width: 91.66667%; }
  .col12_700 {
    width: 100%; } }

@media screen and (max-width: 600px) {
  .col1_600 {
    width: 8.33333%; }
  .col2_600 {
    width: 16.66667%; }
  .col3_600 {
    width: 25%; }
  .col4_600 {
    width: 33.33333%; }
  .col5_600 {
    width: 41.66667%; }
  .col6_600 {
    width: 50%; }
  .col7_600 {
    width: 58.33333%; }
  .col8_600 {
    width: 66.66667%; }
  .col9_600 {
    width: 75%; }
  .col10_600 {
    width: 83.33333%; }
  .col11_600 {
    width: 91.66667%; }
  .col12_600 {
    width: 100%; } }

@media screen and (max-width: 500px) {
  .col1_500 {
    width: 8.33333%; }
  .col2_500 {
    width: 16.66667%; }
  .col3_500 {
    width: 25%; }
  .col4_500 {
    width: 33.33333%; }
  .col5_500 {
    width: 41.66667%; }
  .col6_500 {
    width: 50%; }
  .col7_500 {
    width: 58.33333%; }
  .col8_500 {
    width: 66.66667%; }
  .col9_500 {
    width: 75%; }
  .col10_500 {
    width: 83.33333%; }
  .col11_500 {
    width: 91.66667%; }
  .col12_500 {
    width: 100%; } }

@media screen and (max-width: 425px) {
  .col1_425 {
    width: 8.33333%; }
  .col2_425 {
    width: 16.66667%; }
  .col3_425 {
    width: 25%; }
  .col4_425 {
    width: 33.33333%; }
  .col5_425 {
    width: 41.66667%; }
  .col6_425 {
    width: 50%; }
  .col7_425 {
    width: 58.33333%; }
  .col8_425 {
    width: 66.66667%; }
  .col9_425 {
    width: 75%; }
  .col10_425 {
    width: 83.33333%; }
  .col11_425 {
    width: 91.66667%; }
  .col12_425 {
    width: 100%; } }

@media screen and (max-width: 375px) {
  .col1_375 {
    width: 8.33333%; }
  .col2_375 {
    width: 16.66667%; }
  .col3_375 {
    width: 25%; }
  .col4_375 {
    width: 33.33333%; }
  .col5_375 {
    width: 41.66667%; }
  .col6_375 {
    width: 50%; }
  .col7_375 {
    width: 58.33333%; }
  .col8_375 {
    width: 66.66667%; }
  .col9_375 {
    width: 75%; }
  .col10_375 {
    width: 83.33333%; }
  .col11_375 {
    width: 91.66667%; }
  .col12_375 {
    width: 100%; } }

@media screen and (max-width: 320px) {
  .col1_320 {
    width: 8.33333%; }
  .col2_320 {
    width: 16.66667%; }
  .col3_320 {
    width: 25%; }
  .col4_320 {
    width: 33.33333%; }
  .col5_320 {
    width: 41.66667%; }
  .col6_320 {
    width: 50%; }
  .col7_320 {
    width: 58.33333%; }
  .col8_320 {
    width: 66.66667%; }
  .col9_320 {
    width: 75%; }
  .col10_320 {
    width: 83.33333%; }
  .col11_320 {
    width: 91.66667%; }
  .col12_320 {
    width: 100%; } }

@font-face {
  font-family: 'san_francisco';
  src: url("../fonts/SanFransisco/light.woff2") format("woff2"), url("../fonts/SanFransisco/light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'san_francisco';
  src: url("../fonts/SanFransisco/medium.woff2") format("woff2"), url("../fonts/SanFransisco/medium.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'san_francisco';
  src: url("../fonts/SanFransisco/bold.woff2") format("woff2"), url("../fonts/SanFransisco/bold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'san_francisco';
  src: url("../fonts/SanFransisco/heavy.woff2") format("woff2"), url("../fonts/SanFransisco/heavy.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.table {
  display: table; }

.tableCell {
  display: table-cell; }

.flex {
  display: flex; }

.absolute {
  position: absolute; }

.relative {
  position: relative; }

.fixed {
  position: fixed; }

.sticky {
  position: sticky;
  top: 50px; }

.floatLeft {
  float: left; }

.floatRight {
  float: right; }

.textLeft {
  text-align: left; }

.textCenter {
  text-align: center; }

.textRight {
  text-align: right; }

.alignTop {
  vertical-align: top; }

.alignMiddle {
  vertical-align: middle; }

.alignBottom {
  vertical-align: bottom; }

.caps {
  text-transform: capitalize; }

.allCaps {
  text-transform: uppercase; }

.w2000 {
  max-width: 2000px;
  margin: 0 auto; }
  @media screen and (max-width: 2000px) {
    .w2000 {
      margin: 0; } }

.w1500 {
  max-width: 1500px;
  margin: 0 auto; }
  @media screen and (max-width: 1500px) {
    .w1500 {
      margin: 0; } }

.w1200 {
  max-width: 1200px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .w1200 {
      margin: 0; } }

.w1100 {
  max-width: 1100px;
  margin: 0 auto; }
  @media screen and (max-width: 1100px) {
    .w1100 {
      margin: 0; } }

.w900 {
  max-width: 900px;
  margin: 0 auto; }
  @media screen and (max-width: 900px) {
    .w900 {
      margin: 0; } }

.w800 {
  max-width: 800px;
  margin: 0 auto; }
  @media screen and (max-width: 800px) {
    .w800 {
      margin: 0; } }

.w550 {
  max-width: 550px;
  margin: 0 auto; }
  @media screen and (max-width: 550px) {
    .w550 {
      margin: 0; } }

.yp190 {
  padding-top: 190px;
  padding-bottom: 190px; }
  @media screen and (max-width: 1400px) {
    .yp190 {
      padding-top: 180px;
      padding-bottom: 180px; } }
  @media screen and (max-width: 1200px) {
    .yp190 {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media screen and (max-width: 1024px) {
    .yp190 {
      padding-top: 130px;
      padding-bottom: 130px; } }
  @media screen and (max-width: 768px) {
    .yp190 {
      padding-top: 90px;
      padding-bottom: 90px; } }
  @media screen and (max-width: 500px) {
    .yp190 {
      padding-top: 70px;
      padding-bottom: 70px; } }

.tp190 {
  padding-top: 190px; }
  @media screen and (max-width: 1400px) {
    .tp190 {
      padding-top: 180px; } }
  @media screen and (max-width: 1200px) {
    .tp190 {
      padding-top: 160px; } }
  @media screen and (max-width: 1024px) {
    .tp190 {
      padding-top: 130px; } }
  @media screen and (max-width: 768px) {
    .tp190 {
      padding-top: 90px; } }
  @media screen and (max-width: 500px) {
    .tp190 {
      padding-top: 70px; } }

.bp190 {
  padding-bottom: 190px; }
  @media screen and (max-width: 1400px) {
    .bp190 {
      padding-bottom: 180px; } }
  @media screen and (max-width: 1200px) {
    .bp190 {
      padding-bottom: 160px; } }
  @media screen and (max-width: 1024px) {
    .bp190 {
      padding-bottom: 130px; } }
  @media screen and (max-width: 768px) {
    .bp190 {
      padding-bottom: 90px; } }
  @media screen and (max-width: 500px) {
    .bp190 {
      padding-bottom: 70px; } }

.yp160 {
  padding-top: 160px;
  padding-bottom: 160px; }
  @media screen and (max-width: 1400px) {
    .yp160 {
      padding-top: 140px;
      padding-bottom: 140px; } }
  @media screen and (max-width: 1200px) {
    .yp160 {
      padding-top: 110px;
      padding-bottom: 110px; } }
  @media screen and (max-width: 1024px) {
    .yp160 {
      padding-top: 90px;
      padding-bottom: 90px; } }
  @media screen and (max-width: 768px) {
    .yp160 {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media screen and (max-width: 500px) {
    .yp160 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.tp160 {
  padding-top: 160px; }
  @media screen and (max-width: 1400px) {
    .tp160 {
      padding-top: 140px; } }
  @media screen and (max-width: 1200px) {
    .tp160 {
      padding-top: 110px; } }
  @media screen and (max-width: 1024px) {
    .tp160 {
      padding-top: 90px; } }
  @media screen and (max-width: 768px) {
    .tp160 {
      padding-top: 70px; } }
  @media screen and (max-width: 500px) {
    .tp160 {
      padding-top: 50px; } }

.bp160 {
  padding-bottom: 160px; }
  @media screen and (max-width: 1400px) {
    .bp160 {
      padding-bottom: 140px; } }
  @media screen and (max-width: 1200px) {
    .bp160 {
      padding-bottom: 110px; } }
  @media screen and (max-width: 1024px) {
    .bp160 {
      padding-bottom: 90px; } }
  @media screen and (max-width: 768px) {
    .bp160 {
      padding-bottom: 70px; } }
  @media screen and (max-width: 500px) {
    .bp160 {
      padding-bottom: 50px; } }

.yp130 {
  padding-top: 130px;
  padding-bottom: 130px; }
  @media screen and (max-width: 1400px) {
    .yp130 {
      padding-top: 110px;
      padding-bottom: 110px; } }
  @media screen and (max-width: 1200px) {
    .yp130 {
      padding-top: 90px;
      padding-bottom: 90px; } }
  @media screen and (max-width: 1024px) {
    .yp130 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 500px) {
    .yp130 {
      padding-top: 70px;
      padding-bottom: 70px; } }

.tp130 {
  padding-top: 130px; }
  @media screen and (max-width: 1400px) {
    .tp130 {
      padding-top: 110px; } }
  @media screen and (max-width: 1200px) {
    .tp130 {
      padding-top: 90px; } }
  @media screen and (max-width: 1024px) {
    .tp130 {
      padding-top: 80px; } }
  @media screen and (max-width: 500px) {
    .tp130 {
      padding-top: 70px; } }

.bp130 {
  padding-bottom: 130px; }
  @media screen and (max-width: 1400px) {
    .bp130 {
      padding-bottom: 110px; } }
  @media screen and (max-width: 1200px) {
    .bp130 {
      padding-bottom: 90px; } }
  @media screen and (max-width: 1024px) {
    .bp130 {
      padding-bottom: 80px; } }
  @media screen and (max-width: 500px) {
    .bp130 {
      padding-bottom: 70px; } }

.yp100 {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media screen and (max-width: 1400px) {
    .yp100 {
      padding-top: 90px;
      padding-bottom: 90px; } }
  @media screen and (max-width: 1024px) {
    .yp100 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yp100 {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media screen and (max-width: 500px) {
    .yp100 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.tp100 {
  padding-top: 100px; }
  @media screen and (max-width: 1400px) {
    .tp100 {
      padding-top: 90px; } }
  @media screen and (max-width: 1024px) {
    .tp100 {
      padding-top: 80px; } }
  @media screen and (max-width: 768px) {
    .tp100 {
      padding-top: 70px; } }
  @media screen and (max-width: 500px) {
    .tp100 {
      padding-top: 50px; } }

.bp100 {
  padding-bottom: 100px; }
  @media screen and (max-width: 1400px) {
    .bp100 {
      padding-bottom: 90px; } }
  @media screen and (max-width: 1024px) {
    .bp100 {
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .bp100 {
      padding-bottom: 70px; } }
  @media screen and (max-width: 500px) {
    .bp100 {
      padding-bottom: 50px; } }

.yp70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.tp70 {
  padding-top: 70px; }

.bp70 {
  padding-bottom: 70px; }

.yp50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.tp50 {
  padding-top: 50px; }

.bp50 {
  padding-bottom: 50px; }

.xp150 {
  padding-right: 150px;
  padding-left: 150px; }
  @media screen and (max-width: 1400px) {
    .xp150 {
      padding-right: 130px;
      padding-left: 130px; } }
  @media screen and (max-width: 1200px) {
    .xp150 {
      padding-right: 100px;
      padding-left: 100px; } }
  @media screen and (max-width: 1024px) {
    .xp150 {
      padding-right: 50px;
      padding-left: 50px; } }
  @media screen and (max-width: 768px) {
    .xp150 {
      padding-right: 25px;
      padding-left: 25px; } }

.rp150 {
  padding-right: 150px; }
  @media screen and (max-width: 1400px) {
    .rp150 {
      padding-right: 130px; } }
  @media screen and (max-width: 1200px) {
    .rp150 {
      padding-right: 100px; } }
  @media screen and (max-width: 1024px) {
    .rp150 {
      padding-right: 50px; } }
  @media screen and (max-width: 768px) {
    .rp150 {
      padding-right: 25px; } }

.lp150 {
  padding-left: 150px; }
  @media screen and (max-width: 1400px) {
    .lp150 {
      padding-left: 130px; } }
  @media screen and (max-width: 1200px) {
    .lp150 {
      padding-left: 100px; } }
  @media screen and (max-width: 1024px) {
    .lp150 {
      padding-left: 50px; } }
  @media screen and (max-width: 768px) {
    .lp150 {
      padding-left: 25px; } }

.xp60 {
  padding-right: 60px;
  padding-left: 60px; }
  @media screen and (max-width: 768px) {
    .xp60 {
      padding-right: 30px;
      padding-left: 30px; } }

.rp60 {
  padding-right: 60px; }
  @media screen and (max-width: 768px) {
    .rp60 {
      padding-right: 30px; } }

.lp60 {
  padding-left: 60px; }
  @media screen and (max-width: 768px) {
    .lp60 {
      padding-left: 30px; } }

.xp50 {
  padding-right: 50px;
  padding-left: 50px; }
  @media screen and (max-width: 768px) {
    .xp50 {
      padding-right: 30px;
      padding-left: 30px; } }

.rp50 {
  padding-right: 50px; }
  @media screen and (max-width: 768px) {
    .rp50 {
      padding-right: 30px; } }

.lp50 {
  padding-left: 50px; }
  @media screen and (max-width: 768px) {
    .lp50 {
      padding-left: 30px; } }

.nowrap {
  white-space: nowrap; }

.clearfix {
  display: block;
  clear: both; }

.noScroll {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }
  @media screen and (max-width: 768px) {
    .noScroll {
      overflow-y: hidden; } }

.noCustomScroll {
  overflow-y: hidden; }
  @media screen and (max-width: 768px) {
    .noCustomScroll {
      overflow-y: hidden; } }

.noselect {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.hide {
  display: none; }

@media screen and (max-width: 1800px) {
  .hide_1800 {
    display: none; } }

@media screen and (max-width: 1700px) {
  .hide_1700 {
    display: none; } }

@media screen and (max-width: 1600px) {
  .hide_1600 {
    display: none; } }

@media screen and (max-width: 1500px) {
  .hide_1500 {
    display: none; } }

@media screen and (max-width: 1400px) {
  .hide_1400 {
    display: none; } }

@media screen and (max-width: 1300px) {
  .hide_1300 {
    display: none; } }

@media screen and (max-width: 1200px) {
  .hide_1200 {
    display: none; } }

@media screen and (max-width: 1100px) {
  .hide_1100 {
    display: none; } }

@media screen and (max-width: 1024px) {
  .hide_1024 {
    display: none; } }

@media screen and (max-width: 1000px) {
  .hide_1000 {
    display: none; } }

@media screen and (max-width: 900px) {
  .hide_900 {
    display: none; } }

@media screen and (max-width: 800px) {
  .hide_800 {
    display: none; } }

@media screen and (max-width: 768px) {
  .hide_768 {
    display: none; } }

@media screen and (max-width: 700px) {
  .hide_700 {
    display: none; } }

@media screen and (max-width: 600px) {
  .hide_600 {
    display: none; } }

@media screen and (max-width: 500px) {
  .hide_500 {
    display: none; } }

@media screen and (max-width: 400px) {
  .hide_400 {
    display: none; } }

@media screen and (max-width: 300px) {
  .hide_300 {
    display: none; } }

.link {
  position: relative;
  font-weight: 500;
  color: #021439;
  z-index: 1; }
  .link:hover:after {
    height: 23px; }
  .link:after {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: 8px;
    bottom: 0;
    left: -1px;
    background: rgba(147, 148, 151, 0.5);
    z-index: -1;
    transition: height 225ms; }
    @media screen and (max-width: 1200px) {
      .link:after {
        width: 100%;
        left: 0; } }

.btn {
  padding: 48px 75px;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.1em;
  color: white;
  background: #e5002e;
  position: relative;
  overflow: hidden;
  transition: color cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
  z-index: 1;
  text-decoration: none;
  cursor: pointer; }
  .btn::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    transform: translate(-100%, 0);
    background: white;
    transition: transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
    z-index: -1; }
  .btn:hover {
    color: #021439; }
    .btn:hover::after {
      transform: translate(0, 0); }
  .btn.withIcon {
    padding-left: 120px; }
    .btn.withIcon svg {
      fill: white;
      stroke-width: 0;
      transition: fill cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
      height: 28px;
      width: 28px;
      position: absolute;
      left: 78px;
      top: 50%;
      transform: translate(0, -50%); }
    .btn.withIcon:hover svg {
      fill: #021439;
      stroke: #021439; }
  .btn.withArrow {
    padding: 48px 55px;
    padding-right: 170px; }
    .btn.withArrow svg {
      fill: white;
      stroke: white;
      stroke-width: 0;
      transition: stroke cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms;
      height: 14px;
      width: 55px;
      position: absolute;
      right: 60px;
      top: 50%;
      transform: translate(0, -50%) rotate(180deg); }
    .btn.withArrow:hover svg {
      fill: #021439;
      stroke: #021439; }
  .btn.round {
    border-radius: 50px;
    padding: 20px 30px;
    font-size: 1rem;
    transition: background-color cubic-bezier(0.455, 0.03, 0.515, 0.955) 400ms; }
    .btn.round::after {
      content: normal; }
    .btn.round:hover {
      background-color: white; }
    .btn.round.light {
      color: #021439;
      background-color: white; }
      .btn.round.light:hover {
        color: white;
        background-color: #e5002e; }
    .btn.round.redBG {
      color: #021439;
      background-color: white; }
      .btn.round.redBG:hover {
        color: white;
        background-color: #021439; }
  @media screen and (max-width: 1024px) {
    .btn:not(.round) {
      padding: 30px 45px;
      font-size: 18px; }
      .btn:not(.round).withIcon {
        padding-left: 75px; }
        .btn:not(.round).withIcon svg {
          left: 35px; }
      .btn:not(.round).withArrow {
        padding-right: 140px; } }
  .btn.oc-loading {
    pointer-events: none; }

.liste li:not(:last-child) p {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.liste li p {
  padding: 20px 0;
  margin-bottom: 0;
  font-weight: 500;
  color: #021439; }
  @media screen and (max-width: 600px) {
    .liste li p {
      padding: 15px 0; } }

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

/*** Scroll ***/
.scroller body {
  overflow-x: hidden;
  height: 100vh; }

.scroller [data-scroller] {
  overflow-x: hidden;
  height: 100vh; }
  .scroller [data-scroller] [data-scroller-content] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: hidden; }

.scroller [data-height] {
  position: absolute;
  width: 1px;
  top: 0; }

.dynamic h2, .dynamic h3, .dynamic h4, .dynamic p, .dynamic a, .dynamic ul, .dynamic ol, .dynamic table, .dynamic hr, .dynamic img {
  margin: 0; }

.dynamic h2, .dynamic .h2 {
  margin: 20px 0 30px;
  color: #021439;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.8125rem; }
  @media screen and (max-width: 1440px) {
    .dynamic h2, .dynamic .h2 {
      font-size: 2.5rem; } }
  @media screen and (max-width: 1024px) {
    .dynamic h2, .dynamic .h2 {
      font-size: 2.1875rem; } }
  @media screen and (max-width: 768px) {
    .dynamic h2, .dynamic .h2 {
      font-size: 1.875rem; } }
  @media screen and (max-width: 425px) {
    .dynamic h2, .dynamic .h2 {
      font-size: 1.75rem; } }
  @media screen and (max-width: 375px) {
    .dynamic h2, .dynamic .h2 {
      font-size: 1.5625rem; } }
  @media screen and (max-width: 1024px) {
    .dynamic h2, .dynamic .h2 {
      margin: 50px 0 30px; } }
  @media screen and (max-width: 768px) {
    .dynamic h2, .dynamic .h2 {
      margin: 40px 0 20px; } }
  @media screen and (max-width: 500px) {
    .dynamic h2, .dynamic .h2 {
      margin: 30px 0 20px; } }

.dynamic h3, .dynamic .h3 {
  margin: 40px 0 20px;
  color: #e5002e;
  font-size: 1.25rem; }
  @media screen and (max-width: 768px) {
    .dynamic h3, .dynamic .h3 {
      font-size: 1.125rem; } }
  @media screen and (max-width: 1024px) {
    .dynamic h3, .dynamic .h3 {
      margin: 30px 0 20px; } }
  @media screen and (max-width: 768px) {
    .dynamic h3, .dynamic .h3 {
      margin: 20px 0; } }

.dynamic h4, .dynamic .h4 {
  margin: 30px 0 15px; }

.dynamic p {
  font-size: 1.125rem;
  margin: 15px 0;
  line-height: 1.833333333333333em;
  font-weight: 300;
  color: #939497; }
  @media screen and (max-width: 425px) {
    .dynamic p {
      font-size: 1rem; } }
  .dynamic p.larger {
    font-size: 1.4375rem;
    line-height: 1.565217391304348em; }
    @media screen and (max-width: 1024px) {
      .dynamic p.larger {
        font-size: 1.3125rem; } }
    @media screen and (max-width: 768px) {
      .dynamic p.larger {
        font-size: 1.25rem; } }
  .dynamic p a,
  .dynamic p span.internal {
    color: #031c53;
    font-weight: 400;
    position: relative;
    cursor: pointer; }
    .dynamic p a::after,
    .dynamic p span.internal::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #e5002e;
      bottom: -3px;
      left: 0;
      transition: width cubic-bezier(0.215, 0.61, 0.355, 1) 400ms;
      pointer-events: none; }
    .dynamic p a:hover::after,
    .dynamic p span.internal:hover::after {
      width: 0; }

.dynamic a {
  color: #021439;
  text-decoration: none; }

.dynamic strong {
  font-style: normal;
  font-weight: 700; }

.dynamic em {
  font-style: italic;
  font-weight: normal; }

.dynamic pre {
  margin: 10px 0;
  line-height: 1.5;
  color: #031c53;
  white-space: normal;
  font-size: 0.8125rem; }

.dynamic ul, .dynamic ol {
  margin: 20px 0 20px 25px;
  list-style-position: outside;
  list-style-type: inherit; }
  .dynamic ul ul, .dynamic ul ol, .dynamic ol ul, .dynamic ol ol {
    margin: 0 0 0 25px; }
  .dynamic ul li, .dynamic ol li {
    margin-bottom: 10px;
    line-height: 1.5;
    color: #021439;
    font-size: 1.125rem; }
    .dynamic ul li p, .dynamic ol li p {
      margin: 0; }

.dynamic ul {
  list-style-type: disc; }

.dynamic ol {
  list-style-type: decimal; }

.dynamic .underline {
  display: inline; }

.dynamic .quote, .dynamic blockquote {
  position: relative;
  max-width: 1000px;
  padding: 5px 0 5px 30px;
  margin: 30px 0;
  font-weight: 500;
  font-style: italic;
  line-height: 1.5;
  color: #021439;
  font-size: 1rem; }
  .dynamic .quote:after, .dynamic blockquote:after {
    content: '';
    position: absolute;
    width: 5px;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(229, 0, 46, 0.4); }

.dynamic.light h1, .dynamic.light h2, .dynamic.light h3, .dynamic.light h4, .dynamic.light p, .dynamic.light a, .dynamic.light ul, .dynamic.light ol {
  color: white; }

.dynamic.light h3 {
  color: #e5002e; }

.dynamic.light .btn:hover {
  color: #021439; }

.dynamic.light a, .dynamic.light span.internal {
  color: white; }

.dynamic h1:first-child, .dynamic h2:first-child, .dynamic h3:first-child, .dynamic h4:first-child, .dynamic p:first-child, .dynamic a:first-child, .dynamic ul:first-child, .dynamic ol:first-child, .dynamic table:first-child {
  margin-top: 0 !important; }

.dynamic h1:last-child, .dynamic h2:last-child, .dynamic h3:last-child, .dynamic h4:last-child, .dynamic p:last-child, .dynamic a:last-child, .dynamic ul:last-child, .dynamic ol:last-child, .dynamic table:last-child {
  margin-bottom: 0 !important; }

.redBG .dynamic p a,
.redBG .dynamic p span.internal {
  color: white;
  cursor: pointer; }
  .redBG .dynamic p a::after,
  .redBG .dynamic p span.internal::after {
    background-color: white; }

.drawers .dynamic h2 {
  font-size: 1.4375rem;
  font-weight: 500;
  text-transform: none; }
  @media screen and (max-width: 768px) {
    .drawers .dynamic h2 {
      font-size: 1.3125rem; } }
  @media screen and (max-width: 425px) {
    .drawers .dynamic h2 {
      font-size: 1.25rem; } }
  @media screen and (max-width: 375px) {
    .drawers .dynamic h2 {
      font-size: 1.125rem; } }

.drawers .dynamic p a,
.drawers .dynamic p span.internal {
  color: #031c53; }

header {
  height: 160px;
  position: absolute;
  width: 100%;
  z-index: 1; }
  @media screen and (max-width: 1024px) {
    header {
      display: none; } }
  header .w2000 {
    height: 100%; }
  header .headerContainer {
    display: flex;
    flex-wrap: wrap;
    height: 100%; }
    header .headerContainer .logo {
      width: 25%;
      height: 100%; }
      @media screen and (max-width: 1400px) {
        header .headerContainer .logo {
          width: 20%; } }
      header .headerContainer .logo a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px; }
        header .headerContainer .logo a svg {
          width: 285px;
          height: 100%;
          fill: #021439;
          max-width: 100%; }
    header .headerContainer .menu {
      width: 75%;
      padding-left: 65px;
      display: flex; }
      @media screen and (max-width: 1400px) {
        header .headerContainer .menu {
          width: 80%;
          padding-left: 30px; } }
      header .headerContainer .menu ul {
        display: flex;
        height: 100%; }
        header .headerContainer .menu ul li a {
          display: flex;
          align-items: center;
          color: white;
          font-weight: 300;
          font-size: 1rem;
          height: 100%;
          padding: 0 22px;
          text-decoration: none; }
          header .headerContainer .menu ul li a span {
            display: inline-block;
            position: relative; }
            header .headerContainer .menu ul li a span::after {
              content: '';
              display: block;
              position: absolute;
              bottom: -6px;
              background-color: #e5002e;
              width: 0;
              height: 2px;
              transition: width cubic-bezier(0.215, 0.61, 0.355, 1) 400ms; }
            header .headerContainer .menu ul li a span.rounded {
              border: #e5002e 2px solid;
              border-radius: 50px;
              padding: 10px 18px;
              overflow: hidden;
              transition: background-color cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s; }
          header .headerContainer .menu ul li a:hover span::after {
            width: 100%; }
          header .headerContainer .menu ul li a:hover span.rounded {
            background-color: #e5002e; }
      header .headerContainer .menu .tel {
        flex-grow: 1;
        text-align: right;
        height: 100%;
        color: white;
        font-weight: bold; }
        @media screen and (max-width: 1400px) {
          header .headerContainer .menu .tel {
            padding-right: 40px; } }
        @media screen and (max-width: 1300px) {
          header .headerContainer .menu .tel {
            padding-right: 20px;
            font-size: 14px;
            font-weight: normal; } }
        @media screen and (max-width: 1200px) {
          header .headerContainer .menu .tel {
            display: none; } }
        header .headerContainer .menu .tel a {
          display: inline-flex;
          height: 100%;
          align-items: center;
          color: white;
          text-decoration: none; }

.headerMobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 9999;
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); }
  @media screen and (max-width: 1024px) {
    .headerMobile {
      display: block; } }
  .headerMobile .logo {
    height: 100%;
    padding-right: 80px; }
    .headerMobile .logo a {
      height: 100%;
      display: block; }
      .headerMobile .logo a svg {
        max-width: 100%;
        height: 100%;
        fill: #021439; }
  .headerMobile .mobileNavBtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    cursor: pointer; }
    .headerMobile .mobileNavBtn .hamburger {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%; }

.banner {
  height: 350px;
  position: relative; }
  @media screen and (max-width: 1400px) {
    .banner {
      height: 300px; } }
  @media screen and (max-width: 1024px) {
    .banner {
      height: auto; } }
  .banner .titleContainer {
    position: absolute;
    width: 75%;
    left: 25%;
    right: 0;
    bottom: 0;
    padding-left: 87px;
    padding-bottom: 40px; }
    @media screen and (max-width: 1400px) {
      .banner .titleContainer {
        padding-left: 52px;
        width: 80%;
        left: 20%; } }
    @media screen and (max-width: 1024px) {
      .banner .titleContainer {
        height: auto;
        margin-top: 80px;
        width: 100%;
        position: static;
        padding-top: 40px;
        padding-left: 60px;
        padding-right: 60px; } }
    @media screen and (max-width: 768px) {
      .banner .titleContainer {
        padding-left: 30px;
        padding-right: 30px; } }
    .banner .titleContainer h1 {
      font-size: 6.1875rem;
      line-height: 1.030em;
      color: white;
      font-weight: 700;
      text-transform: uppercase; }
      @media screen and (max-width: 1440px) {
        .banner .titleContainer h1 {
          font-size: 5rem; } }
      @media screen and (max-width: 1024px) {
        .banner .titleContainer h1 {
          font-size: 4.375rem; } }
      @media screen and (max-width: 768px) {
        .banner .titleContainer h1 {
          font-size: 3.125rem; } }
      @media screen and (max-width: 425px) {
        .banner .titleContainer h1 {
          font-size: 2.5rem; } }
      @media screen and (max-width: 375px) {
        .banner .titleContainer h1 {
          font-size: 1.875rem; } }
      @media screen and (max-width: 320px) {
        .banner .titleContainer h1 {
          font-size: 1.75rem; } }
  .banner .background {
    position: absolute;
    left: 25%;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: #021439;
    background-image: url(../images/content/banner/bannerBG.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top; }
    @media screen and (max-width: 1400px) {
      .banner .background {
        left: 20%; } }
    @media screen and (max-width: 1024px) {
      .banner .background {
        left: 0%; } }

footer {
  font-size: 1rem;
  font-weight: 300; }
  footer .grid {
    align-items: center; }
    @media screen and (max-width: 1024px) {
      footer .grid .logo {
        padding-bottom: 30px; } }
    @media screen and (max-width: 768px) {
      footer .grid .logo {
        text-align: center; } }
    footer .grid .logo svg {
      width: 100%;
      height: 30px;
      max-width: 312px;
      fill: #021439; }
    footer .grid ul {
      display: flex;
      padding-bottom: 30px;
      justify-content: flex-end; }
      @media screen and (max-width: 1024px) {
        footer .grid ul {
          justify-content: flex-start; } }
      @media screen and (max-width: 768px) {
        footer .grid ul {
          display: none; } }
      footer .grid ul li {
        padding-left: 40px; }
        footer .grid ul li a {
          color: #021439;
          text-decoration: none;
          font-weight: 400;
          position: relative; }
          footer .grid ul li a::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -4px;
            background-color: #e5002e;
            width: 0;
            height: 2px;
            transition: width cubic-bezier(0.215, 0.61, 0.355, 1) 400ms; }
          footer .grid ul li a:hover::after {
            width: 100%; }
        footer .grid ul li:first-child {
          padding-left: 0; }
    footer .grid .copy {
      color: #939497; }
      @media screen and (max-width: 1024px) {
        footer .grid .copy {
          text-align: left; } }
      footer .grid .copy a {
        position: relative;
        color: #939497;
        text-decoration: none;
        display: inline-block;
        margin-left: 40px; }
        footer .grid .copy a::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -4px;
          background-color: #dcdde2;
          width: 0;
          height: 2px;
          transition: width cubic-bezier(0.215, 0.61, 0.355, 1) 400ms; }
        footer .grid .copy a:hover::after {
          width: 100%; }
      footer .grid .copy span.social {
        margin-left: 40px; }
        footer .grid .copy span.social a {
          margin-left: 12px; }
          footer .grid .copy span.social a svg {
            fill: #021439;
            width: 15px;
            height: 15px;
            transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s; }
          footer .grid .copy span.social a:hover svg {
            opacity: 0.7; }
          footer .grid .copy span.social a::after {
            content: normal; }
      @media screen and (max-width: 768px) {
        footer .grid .copy a, footer .grid .copy span {
          margin-left: 0;
          text-align: center;
          display: block; }
        footer .grid .copy a {
          margin-top: 15px; }
          footer .grid .copy a:hover::after {
            display: none; }
        footer .grid .copy span.social {
          margin-top: 15px;
          margin-left: 0; }
          footer .grid .copy span.social a {
            margin-top: 0;
            display: inline-block; } }

.hamburger {
  position: relative;
  width: 30px;
  height: 22px;
  cursor: pointer;
  transition: opacity 300ms; }
  .hamburger span {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background: #021439;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger span:nth-child(1) {
      top: 0px; }
    .hamburger span:nth-child(2), .hamburger span:nth-child(3) {
      top: 9px; }
    .hamburger span:nth-child(4) {
      top: 18px; }

.showOverlayMenu .hamburger span:nth-child(1) {
  width: 0%;
  top: 18px;
  left: 50%;
  opacity: 0; }

.showOverlayMenu .hamburger span:nth-child(2) {
  transform: rotate(45deg); }

.showOverlayMenu .hamburger span:nth-child(3) {
  transform: rotate(-45deg); }

.showOverlayMenu .hamburger span:nth-child(4) {
  width: 0%;
  top: 18px;
  left: 50%;
  opacity: 0; }

.showOverlayMenu.closingMenu .hamburger span {
  width: 100%;
  left: 0;
  opacity: 1;
  transform: rotate(0deg);
  transition: 250ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .showOverlayMenu.closingMenu .hamburger span:nth-child(1) {
    top: 0px; }
  .showOverlayMenu.closingMenu .hamburger span:nth-child(2), .showOverlayMenu.closingMenu .hamburger span:nth-child(3) {
    top: 9px; }
  .showOverlayMenu.closingMenu .hamburger span:nth-child(4) {
    top: 18px; }

.ui-slider {
  position: relative; }
  .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    cursor: default;
    touch-action: none;
    height: 58px;
    width: 100px;
    transform: translate(-50%, -50%); }
  .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    display: block;
    border: 0; }

.ui-slider-horizontal {
  height: 0px; }

/* Component containers
----------------------------------*/
/*.ui-widget-content {
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #333333;
}*/
/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button {
  background: #e5002e;
  font-weight: normal;
  color: #454545;
  border-radius: 60px;
  outline: none;
  cursor: -webkit-grab;
  cursor: grab;
  transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s; }
  .ui-state-default::before, .ui-state-default::after,
  .ui-widget-content .ui-state-default::before,
  .ui-widget-content .ui-state-default::after,
  .ui-widget-header .ui-state-default::before,
  .ui-widget-header .ui-state-default::after,
  .ui-button::before,
  .ui-button::after {
    content: '';
    position: absolute;
    display: block;
    height: 21px;
    width: 12px;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url(../images/icons/white-chevron.svg);
    background-size: cover; }
  .ui-state-default::before,
  .ui-widget-content .ui-state-default::before,
  .ui-widget-header .ui-state-default::before,
  .ui-button::before {
    left: 28px; }
  .ui-state-default::after,
  .ui-widget-content .ui-state-default::after,
  .ui-widget-header .ui-state-default::after,
  .ui-button::after {
    right: 28px;
    transform: translate(0, -50%) rotate(180deg); }
  .ui-state-default.ui-state-active,
  .ui-widget-content .ui-state-default.ui-state-active,
  .ui-widget-header .ui-state-default.ui-state-active,
  .ui-button.ui-state-active {
    opacity: 0.6; }

form {
  display: block;
  width: 100%; }

div.error {
  position: absolute;
  padding-top: 7px;
  top: 100%;
  color: #e5002e; }

.inputField, .textField, .selectField, .checkboxField, .radioField, .fileField {
  position: relative;
  width: 100%;
  margin-bottom: 50px; }
  @media screen and (max-width: 600px) {
    .inputField, .textField, .selectField, .checkboxField, .radioField, .fileField {
      margin-bottom: 45px; } }

/*** INPUT ***/
.inputField {
  position: relative; }
  .inputField input {
    width: 100%;
    height: 45px;
    padding: 0;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    color: white;
    background: transparent;
    transition: border 300ms ease;
    font-size: 1.125rem; }
    @media screen and (max-width: 600px) {
      .inputField input {
        font-size: 1rem;
        line-height: 35px;
        height: 35px; } }
    .inputField input:focus, .inputField input.valid, .inputField input:hover {
      border-bottom: 2px solid rgba(255, 255, 255, 0.5); }
    .inputField input.error {
      border-bottom: 1px solid #eb3f3f; }
  .inputField label {
    position: absolute;
    color: white;
    cursor: text;
    transition: 300ms ease;
    left: 0;
    top: 1.3em;
    transform: translate(0px, -50%);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.125rem;
    font-weight: 300; }
    @media screen and (max-width: 600px) {
      .inputField label {
        font-size: 0.9375rem;
        line-height: 35px; } }
  .inputField input:focus + label,
  .inputField input.valid + label,
  .inputField input.error + label,
  .inputField input.notEmpty + label {
    top: -12px;
    font-size: 0.6875rem;
    line-height: 12px;
    cursor: default;
    pointer-events: none; }
    @media screen and (max-width: 600px) {
      .inputField input:focus + label,
      .inputField input.valid + label,
      .inputField input.error + label,
      .inputField input.notEmpty + label {
        top: -10px; } }
  .inputField input.vide + label {
    top: 0px; }
    @media screen and (max-width: 600px) {
      .inputField input.vide + label {
        font-size: 1rem;
        line-height: 35px; } }

/*** TEXTAREA ***/
.textField {
  position: relative; }
  .textField textarea {
    width: 100%;
    height: 39px;
    padding: 0 0 7px 0;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    color: white;
    resize: none;
    outline: none;
    overflow: hidden;
    background: transparent;
    transition: border 300ms ease;
    margin-top: 9px;
    font-family: "san_francisco", sans-serif;
    font-weight: 300;
    font-size: 1.125rem; }
    @media screen and (max-width: 600px) {
      .textField textarea {
        font-size: 1rem;
        min-height: 35px;
        height: 35px;
        margin-top: 3px; } }
    .textField textarea:focus, .textField textarea.valid, .textField textarea:hover {
      border-bottom: 2px solid rgba(255, 255, 255, 0.5); }
    .textField textarea.error {
      border-bottom: 1px solid #eb3f3f; }
  .textField label {
    position: absolute;
    color: white;
    cursor: text;
    transition: 300ms ease;
    font-size: 1.125rem;
    font-weight: 300;
    left: 0;
    top: 1.3em;
    transform: translate(0px, -50%); }
    @media screen and (max-width: 600px) {
      .textField label {
        font-size: 1rem;
        line-height: 35px; } }
  .textField textarea:focus + label,
  .textField textarea.valid + label,
  .textField textarea.error + label,
  .textField textarea.notEmpty + label {
    top: -12px;
    font-size: 0.6875rem;
    line-height: 12px;
    cursor: default;
    pointer-events: none; }
    @media screen and (max-width: 600px) {
      .textField textarea:focus + label,
      .textField textarea.valid + label,
      .textField textarea.error + label,
      .textField textarea.notEmpty + label {
        top: -10px; } }
  .textField textarea.vide + label {
    top: 0px; }
    @media screen and (max-width: 600px) {
      .textField textarea.vide + label {
        font-size: 1rem;
        line-height: 35px; } }

/*** CHECKBOX ***/
.checkboxField {
  position: relative; }
  .checkboxField label {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    color: white;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin: 0 30px 15px 0;
    font-weight: 300;
    font-size: 1.125rem; }
    @media screen and (max-width: 600px) {
      .checkboxField label {
        font-size: 1rem; } }
    .checkboxField label input {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
    .checkboxField label span {
      position: absolute;
      width: 20px;
      height: 20px;
      top: -0;
      left: 0;
      background: #eff2f7;
      transition: background 225ms; }
      .checkboxField label span:before {
        content: '';
        position: absolute;
        left: 50%;
        width: 12px;
        height: 12px;
        top: 50%;
        background: url("../images/icons/crochet.png") no-repeat center/contain;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 225ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    .checkboxField label input:checked ~ span {
      opacity: 1;
      background: #e5002e; }
    .checkboxField label input:checked ~ span:before {
      transform: translate(-50%, -50%) scale(1); }
  .checkboxField.list label {
    display: block;
    margin-top: 20px;
    margin-left: 30px; }
  .checkboxField.list label.master {
    padding: 0;
    margin-left: 0; }

.redBG .inputField .error {
  color: white; }

.redBG .checkboxes .checkboxField label input:checked ~ span {
  background-color: white; }

.redBG .checkboxes .checkboxField label span::before {
  background: url("../images/icons/crochet_blue.png") no-repeat center/contain; }

.drawers {
  margin: 0;
  max-width: none;
  list-style-type: none; }
  @media screen and (max-width: 1024px) {
    .drawers {
      margin-left: 0; } }
  .drawers > li {
    margin-bottom: 20px;
    position: relative;
    background-color: white; }
    .drawers > li:last-child {
      margin-bottom: 0; }
    .drawers > li > .toggle {
      position: relative;
      margin: 0;
      cursor: pointer;
      background-color: white;
      padding: 40px 100px 40px 60px;
      z-index: 1; }
      .drawers > li > .toggle::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width cubic-bezier(0.215, 0.61, 0.355, 1) 400ms;
        background-color: #e5002e; }
      @media screen and (max-width: 1024px) {
        .drawers > li > .toggle {
          padding: 40px 80px 40px 30px; } }
      @media screen and (max-width: 768px) {
        .drawers > li > .toggle {
          padding: 30px 80px 30px 20px; } }
      @media screen and (max-width: 600px) {
        .drawers > li > .toggle {
          padding: 20px 50px 20px 20px; } }
      .drawers > li > .toggle h5 {
        font-weight: 300;
        font-size: 1.4375rem;
        color: #021439;
        transition: color 300ms;
        position: relative;
        z-index: 2;
        transition: color cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s; }
        @media screen and (max-width: 768px) {
          .drawers > li > .toggle h5 {
            font-size: 1.3125rem; } }
        @media screen and (max-width: 425px) {
          .drawers > li > .toggle h5 {
            font-size: 1.25rem; } }
        @media screen and (max-width: 375px) {
          .drawers > li > .toggle h5 {
            font-size: 1.125rem; } }
      .drawers > li > .toggle .chevronContainer {
        position: absolute;
        top: 50%;
        right: 65px;
        transform: translateY(-50%);
        transition: background 300ms;
        z-index: 2; }
        @media screen and (max-width: 1024px) {
          .drawers > li > .toggle .chevronContainer {
            right: 40px; } }
        @media screen and (max-width: 600px) {
          .drawers > li > .toggle .chevronContainer {
            right: 20px; } }
        .drawers > li > .toggle .chevronContainer svg {
          stroke: #e5002e;
          width: 12px;
          height: 21px;
          transform: rotate(-90deg);
          transition: transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s; }
    .drawers > li > .drawer {
      display: none;
      max-width: 100%;
      opacity: 0;
      transform: translateY(100px);
      transition: opacity 300ms, transform 300ms;
      position: relative; }
      .drawers > li > .drawer > div {
        padding: 40px 150px 40px 60px; }
        @media screen and (max-width: 1024px) {
          .drawers > li > .drawer > div {
            padding: 50px 120px 20px 30px; } }
        @media screen and (max-width: 768px) {
          .drawers > li > .drawer > div {
            padding: 50px 120px 20px 20px; } }
        @media screen and (max-width: 600px) {
          .drawers > li > .drawer > div {
            padding: 40px 20px 60px 20px; } }
        @media screen and (max-width: 500px) {
          .drawers > li > .drawer > div {
            padding: 30px 20px 60px 20px; } }
        @media screen and (max-width: 400px) {
          .drawers > li > .drawer > div {
            padding: 20px 20px 60px 20px; } }
      .drawers > li > .drawer .documents {
        max-width: 600px;
        margin-top: 30px; }
        .drawers > li > .drawer .documents li:not(:last-child) {
          margin-bottom: 12px; }
    .drawers > li.open > .toggle::after {
      width: 100%; }
    .drawers > li.open > .toggle .flex > div h5 {
      color: white; }
    .drawers > li.open > .toggle .chevronContainer svg {
      transform: rotate(90deg);
      transition: transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
      stroke: white; }
    .drawers > li.open > .drawer {
      opacity: 1;
      transform: translateY(0);
      transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms, opacity 600ms linear 300ms; }

#overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  z-index: 9;
  transition: opacity 600ms, visibility 0ms ease 600ms; }

.blackOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  z-index: 9;
  transition: opacity 600ms, visibility 0ms ease 600ms; }

#overlayMenu {
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  z-index: 100; }
  #overlayMenu .wrapper {
    position: absolute;
    width: 100%;
    max-width: 400px;
    top: 0;
    right: -100%;
    bottom: 0;
    background: #021439;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    z-index: 101; }
    #overlayMenu .wrapper::-webkit-scrollbar {
      display: none; }
    #overlayMenu .wrapper a.callUs {
      color: white;
      display: block;
      width: 100%;
      padding: 40px 40px 0 40px;
      text-decoration: none;
      font-size: 16px;
      text-align: center; }
    #overlayMenu .wrapper ul {
      padding-top: 40px; }
      #overlayMenu .wrapper ul li a {
        color: white;
        display: block;
        text-decoration: none;
        font-size: 1.25rem;
        font-weight: 600;
        padding: 20px 40px;
        text-transform: uppercase; }

.showOverlayMenu.openingMenu .wrapper {
  overflow: hidden;
  transition: right 700ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.showOverlayMenu.openingMenu #overlayMenu .blackOverlay {
  opacity: 1 !important; }

.showOverlayMenu.closingMenu #overlayMenu .blackOverlay {
  opacity: 0; }

.showOverlayMenu.closingMenu #overlayMenu .wrapper {
  right: -100%;
  transition: right 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.showOverlayMenu #overlayMenu .blackOverlay {
  opacity: 1;
  visibility: visible;
  z-index: 99;
  pointer-events: all;
  transition: opacity 600ms; }

.showOverlayMenu #overlayMenu {
  overflow-y: auto;
  visibility: visible; }
  .showOverlayMenu #overlayMenu .wrapper {
    right: 0px;
    pointer-events: all; }

#overlayBrochure {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  z-index: 100; }
  #overlayBrochure .wrapper {
    position: absolute;
    width: 100%;
    max-width: 500px;
    right: -100%;
    bottom: 0;
    background: #e5002e;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    z-index: 101; }
    @media screen and (max-width: 1024px) {
      #overlayBrochure .wrapper {
        top: 80px; } }
    #overlayBrochure .wrapper::-webkit-scrollbar {
      display: none; }
    #overlayBrochure .wrapper .padding {
      padding: 110px 60px;
      position: relative; }
      @media screen and (max-width: 768px) {
        #overlayBrochure .wrapper .padding {
          padding: 50px 30px; } }
    #overlayBrochure .wrapper .titre {
      color: white;
      text-decoration: none;
      font-weight: 700;
      text-transform: uppercase;
      padding-left: 45px;
      position: relative;
      font-size: 2.8125rem; }
      @media screen and (max-width: 1440px) {
        #overlayBrochure .wrapper .titre {
          font-size: 2.5rem; } }
      @media screen and (max-width: 1024px) {
        #overlayBrochure .wrapper .titre {
          font-size: 2.1875rem; } }
      @media screen and (max-width: 768px) {
        #overlayBrochure .wrapper .titre {
          font-size: 1.875rem; } }
      #overlayBrochure .wrapper .titre svg {
        fill: white;
        width: 26px;
        height: 30px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%); }
    #overlayBrochure .wrapper h3 {
      color: white;
      font-size: 1.25rem;
      line-height: 1.2em; }
    #overlayBrochure .wrapper .close {
      position: absolute;
      top: 40px;
      right: 40px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1) 400ms; }
      @media screen and (max-width: 768px) {
        #overlayBrochure .wrapper .close {
          top: 15px;
          right: 15px; } }
      #overlayBrochure .wrapper .close::before, #overlayBrochure .wrapper .close::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 34px;
        height: 2px;
        background-color: white;
        transform: translate(-50%, -50%) rotate(-45deg); }
      #overlayBrochure .wrapper .close::after {
        transform: translate(-50%, -50%) rotate(45deg); }
      #overlayBrochure .wrapper .close:hover {
        opacity: 0.7; }

.showOverlayBrochure.openingBrochure .wrapper {
  overflow: hidden;
  transition: right 700ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.showOverlayBrochure.openingBrochure #overlayBrochure .blackOverlay {
  opacity: 1 !important; }

.showOverlayBrochure.closingBrochure #overlayBrochure .blackOverlay {
  opacity: 0; }

.showOverlayBrochure.closingBrochure #overlayBrochure .wrapper {
  right: -100%;
  transition: right 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.showOverlayBrochure #overlayBrochure .blackOverlay {
  opacity: 1;
  visibility: visible;
  z-index: 99;
  pointer-events: all;
  transition: opacity 600ms; }

.showOverlayBrochure #overlayBrochure {
  overflow-y: auto;
  visibility: visible; }
  .showOverlayBrochure #overlayBrochure .wrapper {
    right: 0px;
    pointer-events: all; }

[data-fadeup] {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 800ms, transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  [data-fadeup][data-scrollfire="fire"], [data-fadeup][data-multiscrollfire="fire"] {
    opacity: 1;
    transform: none; }

.transition [data-fadeup], .chargement [data-fadeup] {
  opacity: 0 !important;
  transform: translateY(100px) !important; }

.transition [data-scrollfire], .transition [data-multiscrollfire], .chargement [data-scrollfire], .chargement [data-multiscrollfire] {
  transition-delay: 500ms !important; }

[data-scrollfire], [data-multiscrollfire] {
  transition-delay: 0ms; }
  [data-scrollfire][data-delay1], [data-multiscrollfire][data-delay1] {
    transition-delay: 300ms; }
  [data-scrollfire][data-delay2], [data-multiscrollfire][data-delay2] {
    transition-delay: 400ms; }
  [data-scrollfire][data-delay3], [data-multiscrollfire][data-delay3] {
    transition-delay: 500ms; }
  [data-scrollfire][data-delay4], [data-multiscrollfire][data-delay4] {
    transition-delay: 600ms; }
  [data-scrollfire][data-delay5], [data-multiscrollfire][data-delay5] {
    transition-delay: 700ms; }
  [data-scrollfire][data-delay6], [data-multiscrollfire][data-delay6] {
    transition-delay: 800ms; }
  [data-scrollfire][data-delay7], [data-multiscrollfire][data-delay7] {
    transition-delay: 900ms; }

.floating {
  -webkit-animation-name: floating;
          animation-name: floating;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.floating:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

@-webkit-keyframes floating {
  0%, 0.001% {
    transform: translateY(-5px); }
  50% {
    transform: translateY(5px); }
  100% {
    transform: translateY(-5px); } }

@keyframes floating {
  0%, 0.001% {
    transform: translateY(-5px); }
  50% {
    transform: translateY(5px); }
  100% {
    transform: translateY(-5px); } }

#landing {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 9998;
  background: #fff;
  visibility: hidden;
  transition: opacity 400ms, visibility 0ms ease 400ms; }

.chargement #landing {
  opacity: 1;
  visibility: visible; }

#page-transition {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: #fff;
  visibility: hidden;
  z-index: 9999; }

.transition #page-transition {
  top: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.end #page-transition {
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.homeBanner.banner {
  height: 100vh;
  min-height: 600px;
  overflow: hidden; }
  .homeBanner.banner .homeSlider {
    background: #031c53;
    position: absolute;
    top: 280px;
    left: 120px;
    right: 120px;
    bottom: 0;
    margin-bottom: 0; }
    @media screen and (max-width: 1400px) {
      .homeBanner.banner .homeSlider {
        left: 85px;
        right: 85px;
        top: 230px; } }
    @media screen and (max-width: 1024px) {
      .homeBanner.banner .homeSlider {
        right: 0;
        top: 170px; } }
    @media screen and (max-width: 768px) {
      .homeBanner.banner .homeSlider {
        right: 80px;
        left: 0; } }
    @media screen and (max-width: 500px) {
      .homeBanner.banner .homeSlider {
        right: 50px; } }
    .homeBanner.banner .homeSlider .slick-list {
      height: 100%;
      overflow: visible; }
      .homeBanner.banner .homeSlider .slick-list .slick-track {
        height: 100%; }
        .homeBanner.banner .homeSlider .slick-list .slick-track .slick-slide > div {
          height: 100%; }
    .homeBanner.banner .homeSlider .slide {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
      .homeBanner.banner .homeSlider .slide .slideContent {
        position: absolute;
        top: -3rem;
        padding-left: 26%; }
        @media screen and (max-width: 1440px) {
          .homeBanner.banner .homeSlider .slide .slideContent {
            top: -2.5rem; } }
        @media screen and (max-width: 1400px) {
          .homeBanner.banner .homeSlider .slide .slideContent {
            padding-left: 20%; } }
        @media screen and (max-width: 1024px) {
          .homeBanner.banner .homeSlider .slide .slideContent {
            top: -2rem; } }
        @media screen and (max-width: 900px) {
          .homeBanner.banner .homeSlider .slide .slideContent {
            padding-left: 8%; } }
        @media screen and (max-width: 768px) {
          .homeBanner.banner .homeSlider .slide .slideContent {
            top: -1.5rem; } }
        .homeBanner.banner .homeSlider .slide .slideContent .slideTitle {
          font-size: 6.1875rem;
          line-height: 1.030em;
          color: white;
          font-weight: 700;
          text-transform: uppercase; }
          @media screen and (max-width: 1440px) {
            .homeBanner.banner .homeSlider .slide .slideContent .slideTitle {
              font-size: 5rem; } }
          @media screen and (max-width: 1024px) {
            .homeBanner.banner .homeSlider .slide .slideContent .slideTitle {
              font-size: 4.375rem; } }
          @media screen and (max-width: 768px) {
            .homeBanner.banner .homeSlider .slide .slideContent .slideTitle {
              font-size: 3.125rem; } }
          @media screen and (max-width: 425px) {
            .homeBanner.banner .homeSlider .slide .slideContent .slideTitle {
              font-size: 2.5rem; } }
          @media screen and (max-width: 375px) {
            .homeBanner.banner .homeSlider .slide .slideContent .slideTitle {
              font-size: 1.875rem; } }
          @media screen and (max-width: 320px) {
            .homeBanner.banner .homeSlider .slide .slideContent .slideTitle {
              font-size: 1.75rem; } }
        .homeBanner.banner .homeSlider .slide .slideContent .slideButton {
          padding-top: 60px; }
  .homeBanner.banner .brochureBtn {
    position: absolute;
    right: 0;
    bottom: 0; }
    .homeBanner.banner .brochureBtn .btn {
      display: block; }
  .homeBanner.banner .bannerNav {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse; }
    @media screen and (max-width: 768px) {
      .homeBanner.banner .bannerNav {
        display: none; } }
    .homeBanner.banner .bannerNav .prevArrow,
    .homeBanner.banner .bannerNav .nextArrow {
      height: 100%;
      width: 120px;
      padding-top: 100%;
      cursor: pointer;
      position: relative;
      background-color: white; }
      @media screen and (max-width: 1400px) {
        .homeBanner.banner .bannerNav .prevArrow,
        .homeBanner.banner .bannerNav .nextArrow {
          width: 85px; } }
      .homeBanner.banner .bannerNav .prevArrow svg,
      .homeBanner.banner .bannerNav .nextArrow svg {
        width: 30px;
        height: 30px;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        stroke: white; }
    .homeBanner.banner .bannerNav .prevArrow {
      background: #031c53; }
    .homeBanner.banner .bannerNav .nextArrow svg {
      transform: translate(-50%, -50%) rotate(180deg);
      stroke: #021439; }
  .homeBanner.banner .bannerDots {
    position: absolute;
    width: 100px;
    top: 50%;
    right: 0;
    transform: translate(0, -50%); }
    @media screen and (max-width: 768px) {
      .homeBanner.banner .bannerDots {
        width: 50px; } }
    .homeBanner.banner .bannerDots ul.slick-dots {
      position: static;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .homeBanner.banner .bannerDots ul.slick-dots li {
        position: relative;
        margin: 5px 0; }
        .homeBanner.banner .bannerDots ul.slick-dots li button {
          position: relative; }
          .homeBanner.banner .bannerDots ul.slick-dots li button::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 7px;
            height: 7px;
            background: white;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            opacity: 1;
            transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s; }
        .homeBanner.banner .bannerDots ul.slick-dots li.slick-active button::before {
          width: 11px;
          height: 11px; }
        .homeBanner.banner .bannerDots ul.slick-dots li:not(.slick-active):hover button::before {
          opacity: 0.6; }
  @media screen and (max-width: 1024px) {
    .homeBanner.banner .background {
      left: 20%; } }
  @media screen and (max-width: 900px) {
    .homeBanner.banner .background {
      left: 0%; } }

.btnRotationWrapper {
  z-index: 999;
  position: absolute;
  right: 5%;
  bottom: -80%; }
  .btnRotationWrapper .btnRotation {
    top: 150px;
    right: 40px;
    opacity: 1;
    z-index: 90;
    transition: z-index 0ms linear 600ms, opacity 300ms linear 600ms, top 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    @media screen and (max-width: 1200px) {
      .btnRotationWrapper .btnRotation {
        right: 30px; } }
    @media screen and (max-width: 1024px) {
      .btnRotationWrapper .btnRotation {
        top: 100px;
        right: 20px; } }
    @media screen and (max-width: 768px) {
      .btnRotationWrapper .btnRotation {
        display: none; } }
    .btnRotationWrapper .btnRotation.top {
      top: 40px; }
      @media screen and (max-width: 1200px) {
        .btnRotationWrapper .btnRotation.top {
          top: 30px; } }
      @media screen and (max-width: 1024px) {
        .btnRotationWrapper .btnRotation.top {
          top: 20px; } }
      @media screen and (max-width: 600px) {
        .btnRotationWrapper .btnRotation.top {
          top: 10px; } }
    .btnRotationWrapper .btnRotation svg {
      width: 100px;
      height: 100px;
      fill: #e5002e; }
      .btnRotationWrapper .btnRotation svg.rotating {
        -webkit-animation: rotating 10s linear infinite;
                animation: rotating 10s linear infinite; }

@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
      @media screen and (max-width: 600px) {
        .btnRotationWrapper .btnRotation svg {
          display: none; } }
    .btnRotationWrapper .btnRotation .rond {
      position: absolute;
      width: 54px;
      height: 54px;
      top: 50%;
      left: 50%;
      background: #e5002e;
      border-radius: 50%;
      transform: translate(-50%, -50%); }
      .btnRotationWrapper .btnRotation .rond svg {
        position: absolute;
        fill: white;
        stroke: white;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        transform: translate(-50%, -50%); }

section.innovation {
  background-color: #fbfbfb; }
  section.innovation .textCenter {
    position: relative; }
  section.innovation .clickNdrag {
    color: #e5002e;
    font-size: 1rem; }
    @media screen and (max-width: 768px) {
      section.innovation .clickNdrag {
        display: none; } }
  section.innovation .rotation360 {
    width: 100%;
    padding-top: 60%;
    position: relative; }
    section.innovation .rotation360 .images img {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%; }
    section.innovation .rotation360 .drag {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      max-width: 600px; }
  section.innovation .rotationMobile {
    display: none; }
    @media screen and (max-width: 768px) {
      section.innovation .rotationMobile {
        display: block; } }
    section.innovation .rotationMobile img {
      max-width: 100%; }

section.benefits {
  background-color: #021439; }
  section.benefits h2 {
    max-width: 500px; }
  section.benefits .column .card {
    background-color: #031c53;
    padding: 70px 50px;
    height: 100%; }
    section.benefits .column .card .iconContainer {
      height: 88px; }
      section.benefits .column .card .iconContainer svg {
        fill: #e5002e;
        height: 88px;
        width: 88px; }
      section.benefits .column .card .iconContainer .simple {
        height: 74px;
        width: 74px;
        background-color: #e5002e;
        position: relative; }
        section.benefits .column .card .iconContainer .simple::before, section.benefits .column .card .iconContainer .simple::after {
          content: '';
          position: absolute;
          height: 4.5px;
          width: 100%;
          background-color: #031c53;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        section.benefits .column .card .iconContainer .simple::after {
          transform: translate(-50%, -50%) rotate(90deg); }
    section.benefits .column .card h3 {
      color: white;
      font-size: 1.75rem; }
      @media screen and (max-width: 1440px) {
        section.benefits .column .card h3 {
          font-size: 1.625rem; } }
      @media screen and (max-width: 1024px) {
        section.benefits .column .card h3 {
          font-size: 1.5rem; } }
      @media screen and (max-width: 768px) {
        section.benefits .column .card h3 {
          font-size: 1.375rem; } }
      @media screen and (max-width: 425px) {
        section.benefits .column .card h3 {
          font-size: 1.25rem; } }
  @media screen and (max-width: 1024px) {
    section.benefits .column:not(:first-child) {
      padding-top: 30px; } }

section.cta-faq {
  background-color: #e5002e; }
  section.cta-faq span {
    font-size: 23px;
    padding-right: 70px; }
    @media screen and (max-width: 1024px) {
      section.cta-faq span {
        display: block;
        padding-right: 0; } }

section.caracteristics {
  background-color: #031c53;
  overflow: hidden; }
  section.caracteristics .slick-slider .slick-list {
    overflow: visible !important;
    margin: 0 -40px; }
    section.caracteristics .slick-slider .slick-list .slick-slide {
      margin: 0 40px; }
      @media screen and (max-width: 768px) {
        section.caracteristics .slick-slider .slick-list .slick-slide {
          margin: 0 10px; } }
  section.caracteristics .caracteristics_pager {
    display: flex;
    color: white;
    font-size: 1.125rem;
    font-weight: 300;
    justify-content: center;
    flex-wrap: wrap; }
    @media screen and (max-width: 425px) {
      section.caracteristics .caracteristics_pager {
        font-size: 1rem; } }
    @media screen and (max-width: 768px) {
      section.caracteristics .caracteristics_pager {
        display: none; } }
    section.caracteristics .caracteristics_pager ul {
      display: flex;
      flex-wrap: wrap; }
      section.caracteristics .caracteristics_pager ul li:not(:first-child) {
        margin-left: 50px; }
      section.caracteristics .caracteristics_pager ul li span {
        cursor: pointer;
        font-weight: 400;
        position: relative;
        display: inline-block; }
        section.caracteristics .caracteristics_pager ul li span::after {
          content: '';
          height: 2px;
          width: 0;
          background-color: #e5002e;
          position: absolute;
          bottom: -5px;
          left: 0;
          transition: width cubic-bezier(0.215, 0.61, 0.355, 1) 400ms;
          pointer-events: none; }
        section.caracteristics .caracteristics_pager ul li span:hover::after, section.caracteristics .caracteristics_pager ul li span.active::after {
          width: 100%; }
    @media screen and (max-width: 1024px) {
      section.caracteristics .caracteristics_pager div {
        width: 100%;
        padding-bottom: 30px;
        text-align: center; }
      section.caracteristics .caracteristics_pager ul li:first-child {
        margin-left: 0; } }
  section.caracteristics .caracteristicsSlides .slide {
    background-color: white;
    min-height: 445px;
    display: inline-flex !important; }
    @media screen and (max-width: 1024px) {
      section.caracteristics .caracteristicsSlides .slide {
        min-height: 360px; } }
    section.caracteristics .caracteristicsSlides .slide .content {
      padding: 40px 80px;
      height: 100%;
      display: flex;
      align-items: center; }
      @media screen and (max-width: 1024px) {
        section.caracteristics .caracteristicsSlides .slide .content {
          padding: 30px 40px; } }
      section.caracteristics .caracteristicsSlides .slide .content h4 {
        font-size: 1.75rem;
        color: #021439;
        margin-bottom: 25px; }
        @media screen and (max-width: 1440px) {
          section.caracteristics .caracteristicsSlides .slide .content h4 {
            font-size: 1.625rem; } }
        @media screen and (max-width: 1024px) {
          section.caracteristics .caracteristicsSlides .slide .content h4 {
            font-size: 1.5rem; } }
        @media screen and (max-width: 768px) {
          section.caracteristics .caracteristicsSlides .slide .content h4 {
            font-size: 1.375rem; } }
        @media screen and (max-width: 425px) {
          section.caracteristics .caracteristicsSlides .slide .content h4 {
            font-size: 1.25rem; } }
      section.caracteristics .caracteristicsSlides .slide .content p {
        color: #939497; }
    section.caracteristics .caracteristicsSlides .slide .image {
      height: 100%;
      background-color: #dcdde2;
      background-size: cover; }
  section.caracteristics .caracteristicsSlides .slick-next.slick-arrow {
    top: -15px;
    background: #e5002e;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    transform: none;
    right: 30px;
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.5);
    transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1) 400ms;
    opacity: 0; }
    @media screen and (max-width: 1024px) {
      section.caracteristics .caracteristicsSlides .slick-next.slick-arrow {
        opacity: 1; } }
    @media screen and (max-width: 768px) {
      section.caracteristics .caracteristicsSlides .slick-next.slick-arrow {
        right: -30px; } }
    @media screen and (max-width: 500px) {
      section.caracteristics .caracteristicsSlides .slick-next.slick-arrow {
        right: -50px; } }
    section.caracteristics .caracteristicsSlides .slick-next.slick-arrow::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-image: url(../images/icons/white-chevron.svg);
      width: 12px;
      height: 21px;
      transform: translate(-50%, -50%) rotate(180deg);
      background-size: cover;
      transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1) 400ms; }
  @media screen and (max-width: 1024px) {
    section.caracteristics .sliderContainer {
      padding-left: 60px;
      padding-right: 60px; } }
  @media screen and (max-width: 768px) {
    section.caracteristics .sliderContainer {
      padding-left: 30px;
      padding-right: 30px;
      max-width: 90%;
      margin: 0 5%; } }
  @media screen and (max-width: 600px) {
    section.caracteristics .sliderContainer {
      max-width: 85%;
      margin: 0 12.5%; } }
  @media screen and (max-width: 500px) {
    section.caracteristics .sliderContainer {
      max-width: 80%;
      margin: 0 10%; } }
  section.caracteristics .sliderContainer:hover .slick-next.slick-arrow {
    opacity: 1; }

section.multifaces .grid {
  align-items: center; }
  section.multifaces .grid .logo svg {
    width: 100%;
    max-width: 480px;
    fill: #021439; }

section.contact {
  background-image: url(../images/content/contact_bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    section.contact {
      padding-bottom: 130px; } }
  @media screen and (max-width: 400px) {
    section.contact {
      padding: 0;
      background-image: none; }
      section.contact .grid .column {
        padding: 0; } }
  section.contact .formContainer {
    background-color: #031c53;
    padding: 80px 85px; }
    @media screen and (max-width: 768px) {
      section.contact .formContainer {
        padding: 60px 50px; } }
    section.contact .formContainer .h2 {
      font-weight: 600;
      line-height: 1.2em; }
    section.contact .formContainer form .submitField {
      position: absolute; }

.fixedPatent {
  position: fixed;
  width: 120px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }
  @media screen and (max-width: 1400px) {
    .fixedPatent {
      width: 85px; } }
  @media screen and (max-width: 1024px) {
    .fixedPatent {
      display: none; } }
  .fixedPatent > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    color: #e5002e;
    font-size: 16px;
    text-align: center;
    width: 180px; }

section.faq {
  background-color: #eff0f3; }
  section.faq .dynamic.faqText p {
    color: black; }
